import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IPagination, IPlanAccounts, IResponse } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';


@Injectable({
  providedIn: 'root'
})
export class PlanAccountsService extends GenericCrudService<IPlanAccounts> {

  constructor() { super(ModuleBackend.ACCOUNTING_ACCOUNTS); }

  override readOne(id: number): Observable<IResponse<IPlanAccounts>> { return super.readOne(id); }

  
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {}): Observable<ICrudResponse<IPlanAccounts>> | any { return super.readAll(pagination, extraKeys); }
}
