import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { LocalNotifications, ActionPerformed as ActionPerformedLocal } from "@capacitor/local-notifications";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "@environments/environment";
import { MessagesAlerts } from "./messages-alerts";
import { UtilsFunctions } from "./utils";

// Add the public key generated from the console here.
// getToken(messaging, {vapidKey: environment.VAPID_KEY});
// MOBILE CONFIG NOTIFY

@Injectable({
  providedIn: "root"
})
export class FcmRepo {
  messaging: any;
  tokenWeb: string = '';
  permissionInWeb: boolean = false;

  private pushToken: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private ngZone: NgZone,
    public utilsFunctions: UtilsFunctions,
    private messageAlerts: MessagesAlerts
  ) {
    this.messaging = getMessaging();
  }

  getPushToken() {
    return this.pushToken.value;
  }

  async getTokenWeb(): Promise<any>{

    if(Capacitor.getPlatform() === 'web'){

      if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
          if(permission === 'granted') {
            this.permissionInWeb = true;
          }
        });
      }
    }

    await Notification.requestPermission().then((permission) => {
      if(permission === 'granted') {
        this.permissionInWeb = true;
      }
    });

    if(this.permissionInWeb){
      await getToken(this.messaging, { vapidKey: environment.VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          this.tokenWeb = currentToken;

          onMessage(this.messaging, (payload: any) => {
            this.messageAlerts.openToast(`${payload.notification.title}, ${ payload.notification.body.replace(/<[^>]*>?/gm, '') }`, "primary", 8000, "top" );
          });

          // ...
        } else {
          // Show permission request UI
          this.messageAlerts.openToast("Este dispositivo no tiene permisos para recibir notificaciones", "danger")
          this.tokenWeb = ""
          // ...
        }
      }).catch((err: any) => {
        this.messageAlerts.openToast("Este dispositivo no tiene permisos para recibir notificaciones", "danger")
        this.tokenWeb = ""
        // ...
      });
      return this.tokenWeb;
    } else {
      this.messageAlerts.openToast("Este dispositivo no tiene permisos para recibir notificaciones", "danger");
      return ""
    }
  }

  getPushTokenObs() {
    return this.pushToken.asObservable();
  }

  registerPush() {
    if (Capacitor.getPlatform() !== "web") {
      this.listerForeGroundNotifications();
      this.listenBackgroundNotificationsActions();
      this.listenLocalNotificationsActions();
    }
  }

  unRegisterPush() {
    if (Capacitor.getPlatform() !== "web") {
      PushNotifications.addListener("pushNotificationReceived", () => {});

      PushNotifications.addListener("pushNotificationActionPerformed", () => {});
    }

    this.pushToken.next(null);
  }

  async initPush() {
    if (Capacitor.getPlatform() !== "web") {
      const pushPermissions = await PushNotifications.requestPermissions();
      const localPermissions = await LocalNotifications.requestPermissions();

      if (pushPermissions.receive === "granted" && localPermissions.display === "granted") {
        await PushNotifications.register();
        this.listenToken();
      }
    } else {
      this.pushToken.next("");
    }
  }

  private listenToken() {
    PushNotifications.addListener("registration", (token: Token) => {
      this.pushToken.next(token.value);
    });

    PushNotifications.addListener("registrationError", (error: any) => {
    });
  }

  // Metodo cuando la app esta en primer plano y recibe una notificacion.
  private listerForeGroundNotifications() {
    PushNotifications.addListener("pushNotificationReceived", async (notification: PushNotificationSchema) => {

      console.log('pushNotificationReceived en primer plano', notification);
      let fireLocalNot = true;
    //   const notificationType = notification.data["type"];
    //   const reference = notification.data["reference"];
    //   const urlParts = this.router.url.split("/");

      if (notification.body) notification.body = notification.body.replace(/<[^>]*>?/gm, '');

      if (fireLocalNot) {

        notification.body = 'queee';

        this.fireLocalNotification(notification.title, notification.body, notification.data);
      }
    });
  }

  private listenBackgroundNotificationsActions() {
    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {

      this.ngZone.run(() => {

        const notificationType =  notification.notification.data["route"];
        const reference = notification.notification.data["reference"];

        console.log("Action in notification ", notification)
        console.log("notificationType ", notificationType)

        if (notificationType) {
          this.navigateOnAction(notificationType, reference);
        }
      });
    });
  }

  private listenLocalNotificationsActions() {
    LocalNotifications.addListener("localNotificationActionPerformed", (notification: ActionPerformedLocal) => {
      this.ngZone.run(() => {

        const notificationType = notification.notification.extra["route"];
        const reference = notification.notification.extra["reference"];
        
        console.log("Action in notification ", notification);
        console.log("notificationType ", notificationType);

        if (notificationType) {
          this.navigateOnAction(notificationType, reference);
        } else {
          this.goToNotsScreen()
        }
      });
    });
  }

  private navigateOnAction(notificationType: string, reference: any) {
    // this.goToNotsScreen();
    this.gotoRoute(notificationType, reference)
  }

  gotoRoute(notificationType: string, reference: any){
    this.router.navigate([notificationType], reference? {state: reference} : {});
  }

  async fireLocalNotification(title: any, body: any, data: any) {

    // console.log('fireLocalNotification',title, body, data);

    await LocalNotifications.schedule({
      notifications: [
        {
          title: title,
          body: this.utilsFunctions.stripHtml(body),
          id: Date.now(),
          schedule: {
            at: new Date(Date.now() + (1000 * 5)),
            allowWhileIdle: false
          },
          extra: data,
          iconColor: '#3A7667',
          smallIcon: '/assets/icons/icon-72x72.png',
          largeIcon: '/assets/icons/icon-72x72.png',
          autoCancel: false,
          ongoing: true,
        }
      ]
    });
  }

  private goToNotsScreen() {
    this.router.navigate(["dashboard/home"]);
  }

  public async getDeliveredNotifications(){
    const notifications = await PushNotifications.getDeliveredNotifications();
    console.log('Notificaciones push entregadas:', notifications);
    return notifications;
  }
}
