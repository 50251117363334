<div class="item">
  <div class="w-100">
    <ion-row (click)="showActions()" size="12" class="ion-no-margin row-action-item" [ngClass]="{'margin-bottom-xs': selected === true}">

      <ion-col [size]="selected === true && showButtonActions === true ? 8: 12">
        <ion-text color="secondary-three" [ngClass]="{'overflow-texting': selected !== true}"
          class="text-title" *ngIf="item.title">
          {{ item.title }}
        </ion-text>
      </ion-col>

      <ion-col class="icol-flex-end" size="4" *ngIf="selected === true && showButtonActions === true">
        <ion-button color="tertiary-two" size="small" fill="clear" (click)="deleteNotif()">
          <ion-icon name="trash-outline"></ion-icon>
        </ion-button>
        <ion-button color="tertiary-two" size="small" fill="clear" (click)="editNotif()">
          <ion-icon name="create-outline"></ion-icon>
        </ion-button>
      </ion-col>

    </ion-row>

    <ng-container *ngIf="selected === true && showDescription === true">
      <ion-text (click)="showActions()" color="medium" [ngClass]="{'overflow-texting': selected !== true}"
        class="text-info" *ngIf="item.message && item.selected  && !item.description && !item.message.includes('<')">
        {{ item.message }}
      </ion-text>
      <div
        (click)="showActions()"
        color="medium"
        *ngIf="item.description && !item.message"
        [innerHTML]="item.description"
        [ngClass]="{'overflow-texting': selected !== true}"
        class="text-info"
      >
      </div>
    </ng-container>

    <p class="ion-no-margin" [ngClass]="{'margin-top-xs': item.message, 'margin-top-md': item.NotificationFile && item.NotificationFile.length > 0}">

      <ion-chip (click)="showImages(true)" [outline]="true" *ngIf="item.NotificationFile && item.NotificationFile.length > 0">
        <ion-icon name="images-outline" color="tertiary-two"></ion-icon>
        <ion-label color="tertiary-two">{{ item.NotificationFile.length }} archivos adjunto</ion-label>
      </ion-chip>

      <ion-chip [outline]="true" *ngIf="item.createdAt">
        <ion-icon name="calendar-number-outline" color="tertiary-two"></ion-icon>
        <ion-label color="tertiary-two">{{item.createdAt | date: 'YYYY-MM-dd hh:mm aa'}}</ion-label>
      </ion-chip>

      <ion-chip [outline]="true" *ngIf="item.hour">
        <ion-icon name="stopwatch-outline" color="tertiary-two"></ion-icon>
        <ion-label color="tertiary-two">{{item.hour}}</ion-label>
      </ion-chip>

      <ion-chip [outline]="true" *ngIf="item.target">
        <ion-icon [name]="getIcon(item.target)" color="tertiary-two" ></ion-icon>
        <ion-label color="tertiary-two">{{item.target}}</ion-label>
      </ion-chip>

      <ion-chip [outline]="true" *ngIf="item.typeNotificationId">
        <ion-icon [name]="getIconByType(item.typeNotificationId)" color="tertiary-two" ></ion-icon>
        <ion-label color="tertiary-two">{{getTypeNotif(item.typeNotificationId)}}</ion-label>
      </ion-chip>

      <ion-chip class="chip-status" *ngIf="item.status?.name; else nameCommonArea" [style]="'--background:'+ item.status.color">
        <ion-label>{{item.status.name}}</ion-label>
      </ion-chip>

      <ng-template #nameCommonArea>
        <ion-chip class="chip-status" [style]="'--background: var(--ion-color-primary)'">
          <ion-label>{{item?.eventRequest[0].commonArea.name}}</ion-label>
        </ion-chip>
      </ng-template>

      <ion-chip *ngIf="item.CompoundOnNotifications && item.CompoundOnNotifications.length > 0 && selected !== true" [outline]="true">
        <ion-icon name="business-outline"></ion-icon>
        <ion-label color="tertiary-two">{{item.CompoundOnNotifications.length}} Recintos</ion-label>
      </ion-chip>

      <ng-container *ngIf="item.CompoundOnNotifications && item.CompoundOnNotifications.length > 0 && selected === true">
        <ion-chip  [outline]="true" *ngFor="let compound of item.CompoundOnNotifications">
          <ion-icon name="business-outline"></ion-icon>
          <ion-label color="tertiary-two">{{compound.compound.name}}</ion-label>
        </ion-chip>
      </ng-container>
    </p>
  </div>
</div>

<ion-modal 
  *ngIf="isModalOpen"
  [enterAnimation]="enterAnimation" 
  [leaveAnimation]="leaveAnimation" 
  class="modal-default-radius modalPhoto" 
  [isOpen]="isModalOpen"
  mode="md" 
  keepContentsMounted="true" 
  backdropDismiss="false"
>
  <ng-template>
    <div class="ion-padding not-overflow content-modalPhoto">

      <ion-button shape="round" fill="clear" color="primary" class="btn-close" (click)="showImages(false)">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>

      <swiper-container *ngIf="item && item.NotificationFile" pagination="true">
        <swiper-slide *ngFor="let notif of item?.NotificationFile" loading="lazy">
          <img [src]="notif?.UrlFile" />
        </swiper-slide>
      </swiper-container>
    </div>
  </ng-template>
</ion-modal>

