/* eslint-disable @typescript-eslint/naming-convention */

export const NavigationApp = {

  //#region GENERAL
  STATUS_APP: 'status-app',
  ROOT: '/',
  ERROR: 'not-found',
  //#endregion

  //#region LOGIN
  LOGIN: 'login',
  LOGIN_ACTIONS: 'actions',               // children of "LOGIN"
  LOGIN_FINGERPRINT: 'fingerprint',       // children of "LOGIN"
  RESET_PASSWORD: 'reset-password',     // children of "LOGIN"
  VALIDATE_ACCESS: 'validate-access',     // children of "LOGIN"
  FIRST_ACCESS: 'first-access',
  //#endregion

  //#region REGISTER
  REGISTER: 'register',
  //#endregion

  //#region ASSETS
  ASSETS_IMG: 'assets/img',
  //#endregion

  //#region DASHBOARD
  DASHBOARD: 'dashboard',
  USERS: 'users',
  ADMIN_PROFILES: 'admin-profiles',
  USERS_MENU: 'menu',
  USERS_CONTROLS: 'user-controls',
  EMPLOYESS: 'employess',
  COMPOUND: 'compound',
  PROPERTYOWNER: 'property-owner',
  HOME: 'home',
  SERVICES: 'services',
  SELECT_PROPERTY: 'select-property',     // children of "HOME"
  SELECT_CLIENT: 'select-client',
  PROFILE: 'profile',                     // children of "DASHBOARD"
  PROFILE_USER: 'profile-user',           // children of "DASHBOARD"
  SETTINGS: 'settings',                   // children of "DASHBOARD"
  BILLBOARD: 'billboard',                 // children of "DASHBOARD"
  NOTIFICATIONS: 'notifications',         // children of "NOTIFICATIONS"
  PROPERTY: 'property',
  PAYMENTS: 'payments',
  PAYMENT_METHODS: 'method',       // children of "PAYMENTS"
  CRUD_PAYMENT_METHOD: 'payment-methods',       // crud metodos de pago"
  PAYMENTS_MONEY: "payments-money",
  PAYMENTS_OWNER: "payment-owner",

  BILLS: 'bills',
  BILL_PENDING: 'bill-pending',
  //#endregion

  //#region SETTINGS
  CLIENT_IMAGE: 'client-image',     // children of "settings"
  ADDRESS: 'address',     // children of "settings"
  ADMINISTRATOR: 'administrator',     // children of "settings"
  ADMIN_PROPERTY: 'admin-property',
  SECURITY: 'security',     // children of "settings"
  CHANGE_PASSWORD: 'change-password',     // children of "settings"
  SET_FINGERPRINT: 'set-fingerprint',     // children of "settings"
  VERIFY_IDENTITY: 'verify-identity',     // children of "settings"
  TENANT: 'tenant',     // children of "settings"
  TENANT_ADD: 'add',     // children of "settings"
  CONFIG_SURVEYS: 'config-surveys',
  RESERVE_FUND: 'reserve-fund',   // children of "settings"
  PROVISION_COLLECTIONS: 'provision-collections',   // children of "settings"
  RATE_RECEIPTS: 'rate-receipts',   // children of "settings"
  //#endregion

  //#region BILLBOARD
  GENERAL: 'general',
  IMPORTANT: 'important',
  //#endregion

  //#region SURVEYS
  SURVEYS: 'surveys',
  CREATE_SURVEY: 'create',
  ANSWER_SURVEY: 'answer',
  //#endregion

  //#region PAYMENTS AND RECEIPTS
  PAYMENTS_AND_RECEIPTS: 'payments_and_receipts',
  ADMIN_RECEIPTS_HISTORY: 'admin-receipts-history',
  ADMIN_RECEIPTS_PAYMENT_HISTORY: 'admin-receipts-payment-history',
  ADMIN_GENERATE_RECEIPTS: 'admin-generate-receipts',
  ADMIN_MONTHLY_RECEIPTS: 'admin-monthly-receipts',
  ADMIN_PERSONALIZED_RECEIPTS: 'admin-personalized-receipts',
  ADMIN_PAYMENT_RECONCILIATION: 'admin-payment-reconciliation',
  ADMIN_GENERAL_RECEIPTS: 'admin-general-receipts',
  ADMIN_DISBURSEMENT_DETAILS: 'admin-disbursement-details',
  ADMIN_DISBURSEMENT_REPORT: 'admin-disbursement-report',
  ADMIN_USERS_DEFAULTERS: 'admin-users-defaulters',
  //#endregion

  // #region CALENDAR
  CALENDAR: 'calendar',
  EVENTS: 'events',
  REQUESTS: 'requests',
  COMMON_AREAS: 'common-areas',
  //#endregion

  // #region ACCOUNTING - CONTABILIDAD
  ACCOUNTING: 'accounting',
  PLAN_ACCOUNTS: 'plan-accounts',
  JOURNAL: 'journal',
  VOUCHER_LIST: 'voucher-list',
  GENERAL_RECEIPT: 'general-receipt',
  DISBURSEMENT_DETAILS: 'disbursement-details',
  DISBURSEMENT_REPORT: 'disbursement-report',
  ADMIN_DISBURSEMENT_REPORT_WITH_PROPERTY: 'admin-disbursement-report-with-property',
  OWNER_RECEIPT_HISTORY: 'owner-receip-history',
  GENERAL_RECEIPT_HISTORY: 'general-receipt-history',
  USERS_DEFAULTERS: 'users-defaulters',
  RECEIPT_CONTROL: 'receipt-control',
  PAYMENTS_CASH: 'payments-cash',
  REPORTS_LIST: "report-list",
  INVENTORY: "inventory"
  //#endregion
};
