export const environment = {
  production: false,
  API_URL: "https://dev-back.cintuz.com/api",
  recaptcha: {
    siteKey: "6Lc2hAooAAAAAELa04wCdTLwsP_2OfRWm3s0tTAs"
  },
  VAPID_KEY: "BHToxn_wv8FhS0rwa39VzmAqJXfn0ScFVlo0JsuJo4dqU2I1Eh-pAj5PoEK65FGwnPMoo5qlA9Y-QZpHxzt_Phg",
  LINK_WEB_CINTUZ: 'https://web-dev.cintuz.com/#/home',
  GOOGLE_MAP_API_KEY: "AIzaSyCbdQApB2Yu8YTYToQxLiy5iQbasvtUtJE",
  WAPP_LINK: "https://wa.me/584122484475",
  VERSION_APP: "1.0"
};

// command show SHA-1 y SHA-526 ------->      keytool -list -keystore C:\Users\Orianna\OneDrive\Documents\Gsoxware\cintuz-app\cintuz-app\src\.well-known\com.cintuz.app.jks
// password Abcd12345
