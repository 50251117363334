<div class="content-modal-notify" [ngClass]="{ classContent: classContent }">

  <ion-grid class="content-item">
    <ion-row class="content-text">
      <ion-col size="12">
        <ion-label class="d-block" color="secondary-three">
          {{ title }} <ion-text class="color-subt" *ngIf="subtitle">{{ subtitle }}</ion-text>
        </ion-label>
      </ion-col>
    </ion-row>

    <form *ngIf="!loading && form" [formGroup]="form">
      <ion-row size="12" >
        <ion-col class="item-radio-custom">
            <ion-label class="custom-label">Desde</ion-label>
            <ion-row class="min-h">
              <ion-datetime-button
                datetime="datetimemodalTimed"
                mode="md"
                class="min-h"
                displayFormat="MM/DD/YYYY"
                presentation="date"
              ></ion-datetime-button>

              <ion-modal class="modal-datetime" #modalDatetime mode="md" backdropDismiss="false" [keepContentsMounted]="true">
                <ng-template>
                  <ion-datetime id="datetimemodalTimed"
                    displayFormat="MM/DD/YYYY"
                    [showDefaultButtons]="true"
                    [max]="dateNow"
                    formControlName="startDate"
                    presentation="date"
                    mode="md"
                    #datetimemodal
                  >
                  <ion-buttons slot="buttons">
                    <ion-button fill="outline" class="btn-action" expand="block" color="medium" (click)="datetimemodal.cancel(true)">Cancelar</ion-button>
                    <ion-button fill="outline" class="btn-action" expand="block" color="primary" (click)="datetimemodal.confirm(true)">Confirmar</ion-button>
                  </ion-buttons>
                </ion-datetime>
                </ng-template>
              </ion-modal>

            </ion-row>
        </ion-col>

        <ion-col class="item-radio-custom">
          <ion-label class="custom-label">Hasta</ion-label>
          <ion-row class="min-h">
            <ion-datetime-button
              datetime="datetimemodalTimedend"
              mode="md"
              class="min-h"
              displayFormat="MM/DD/YYYY"
              presentation="date"
            ></ion-datetime-button>

            <ion-modal class="modal-datetime" #modalDatetime mode="md" backdropDismiss="false" [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime id="datetimemodalTimedend"
                  displayFormat="MM/DD/YYYY"
                  [showDefaultButtons]="true"
                  [max]="dateNow"
                  formControlName="endDate"
                  presentation="date"
                  mode="md"
                  #datetimemodal
                >
                <ion-buttons slot="buttons">
                  <ion-button fill="outline" class="btn-action" expand="block" color="medium" (click)="datetimemodal.cancel(true)">Cancelar</ion-button>
                  <ion-button fill="outline" class="btn-action" expand="block" color="primary" (click)="datetimemodal.confirm(true)">Confirmar</ion-button>
                </ion-buttons>
              </ion-datetime>
              </ng-template>
            </ion-modal>

          </ion-row>
        </ion-col>
      </ion-row>

    </form>


  </ion-grid>

  <ion-row [ngClass]="{ classRowAction: classRowAction }" *ngIf="actionButton">
    <ion-col>
      <ion-button [ngClass]="{ classAction0: classAction0 }" fill="outline" (click)="Close()" mode="md" expand="block">
        {{ textBtn && textBtn.length > 0 ? textBtn[0].title: 'ejemplo' }}
      </ion-button>
      <ion-button [ngClass]="{ classAction1: classAction1 }" [disabled]="form.invalid" (click)="EmitAction('dataSend')" fill="outline" mode="md" expand="block">
        {{ textBtn && textBtn.length > 1 ? textBtn[1].title: 'ejemplo' }}
      </ion-button>
    </ion-col>
  </ion-row>
</div>

<ion-modal 
  (ionModalWillDismiss)="openModalDate = false" 
  [keepContentsMounted]="true" 
  [isOpen]="openModalDate" 
  class="modal-date-expense-form"
>
  <ng-template>
    <ion-datetime
      presentation="date"
      locale="es-US-u-hc-419-es"
      mode="md"
      [showDefaultButtons]="true"
      [showClearButton]="false"
      doneText="Confirmar"
      cancelText="Cancelar"
      (ionChange)="SelectDate($event)">
    </ion-datetime>
  </ng-template>
</ion-modal>
