import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { Observable } from 'rxjs';
import { UrlTree } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getAllNotifications(Module: ModuleBackend, pagination: any, filter?: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.page}&skip=${pagination.skip}${filter && filter.isImportant ?'&isImportant='+filter.isImportant : ''}${filter && filter.typeNotificationId?'&typeNotificationId='+filter.typeNotificationId : ''}${filter && filter.status?'&status='+filter.status : ''}${filter && filter.active?'&active='+filter.active : ''}  `,
      this.headerComplement.complementHeader()
    );
  }

  getNotification(Module: ModuleBackend, id: any){
    return this.http.get(
      `${this.API_URI}/${Module}/${id}`,      
      this.headerComplement.complementHeader()
    );
  }

  deletePhotoNotify(Module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.delete(`
    ${this.API_URI}/${Module}/deleteImage/notification/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  deleteNotification(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  updateNotification(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  createNotification(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }
}
