import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { UtilsFunctions } from '@common/functions/utils';


@Injectable({
  providedIn: 'root'
})
export class CommonAreasService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private utilsFunctions: UtilsFunctions,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getAll(Module: ModuleBackend, pagination: any, filter?: any){

    let isPropertyAdmin = this.utilsFunctions.thisUserIsAdmin();

    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.page}&skip=${pagination.skip}${isPropertyAdmin? '&isAdmin='+isPropertyAdmin : ''}`,
      this.headerComplement.complementHeader()
    );
  }

  findOne(Module: ModuleBackend, id: any){
    return this.http.get(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  create(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  delete(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  update(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  loadPhoto(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  deletePhoto(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }
}
