import { ModuleBackend } from '@enums/index';
import { Injectable } from '@angular/core';
import { IClientExpensiveTypes, IClientResidentialTypes, ICondominiumTypes, IDocumentTypes, IMunicipality, IParish, IState, ITypestTitle } from '@interfaces/index';
import { Observable, first } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TableTypesService {
  constructor(private http: HttpClient) { }

  readAllCondominiumTypes(): Observable<ICondominiumTypes[]> {
    return this.http.get<ICondominiumTypes[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/condominiunType`)
      .pipe(first());
  }
  readAllDocumentTypes(): Observable<IDocumentTypes[]> {
    return this.http.get<IDocumentTypes[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/documentTypes`)
      .pipe(first());
  }
  readAllClientResidentialTypes(): Observable<IClientResidentialTypes[]> {
    return this.http.get<IClientResidentialTypes[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/clientResidentialType`)
      .pipe(first());
  }
  readAllClientExpensiveTypes(): Observable<IClientExpensiveTypes[]> {
    return this.http.get<IClientExpensiveTypes[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/clientExpensiveType`)
      .pipe(first());
  }
  readAllTypesTitle(): Observable<ITypestTitle[]> {
    return this.http.get<ITypestTitle[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/titleType`)
      .pipe(first());
  }

  readAllStates(): Observable<IState[]> {
    return this.http.get<IState[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/state`)
      .pipe(first());
  }
  readAllMunicipalitys(stateCode: number): Observable<IMunicipality[]> {
    return this.http.get<IMunicipality[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/municipality/${stateCode}`)
      .pipe(first());
  }
  readAllParishes(stateCode: number, municipalityCode: number): Observable<IParish[]> {
    return this.http.get<IParish[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/parish/${stateCode}/${municipalityCode}`)
      .pipe(first());
  }
  readAllTypesNotifications(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/notificationType-billboard`)
      .pipe(first());
  }

  readAllTypesEvents(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.API_URL}/${ModuleBackend.TABLAS_TIPO}/eventType`
    ).pipe(first());
  }
}
