<div class="content-modal">
  <div class="question-content">
    <ion-text color="secondary-three">{{questionConfirm}}</ion-text>
  </div>
  <div class="icon-content">
    <ion-icon name="help-circle-outline" color="tertiary-two" class="animate__animated animate__zoomIn"></ion-icon>
  </div>
  <div class="detail-content">
    <ion-text color="secondary-three">{{detailElement}}</ion-text>
  </div>
  <div class="content-buttons" >
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col size="6">
          <ion-button fill="outline" expand="block" (click)="cancel()"
            class="button-round ion-no-margin margin-right-xs margin-bottom-xs">
            Cancelar
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button fill="outline" expand="block" (click)="delete()"
            class="button-round ion-no-margin margin-left-xs margin-bottom-xs">
            Eliminar
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>