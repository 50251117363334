import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { ICrudResponse, IPagination, IPropertyReceipt, IReceipts, IResponse } from '@interfaces/*';
import { environment } from '@environments/environment';
import { ModuleBackend } from '@enums/index';


@Injectable({
  providedIn: 'root'
})
export class AdministratorGenerateReceiptsService {
  private loading$: BehaviorSubject<boolean>;
  private saveDone$: BehaviorSubject<boolean>;
  private title$: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.loading$ = new BehaviorSubject<boolean>(true);
    this.saveDone$ = new BehaviorSubject<boolean>(false);
    this.title$ = new BehaviorSubject<string>('');
  }

  setLoading(value: boolean) { this.loading$.next(value); }
  getLoading(): boolean { return this.loading$.getValue(); }
  setSaveDone(value: boolean) { this.saveDone$.next(value); }
  getSaveDone(): boolean { return this.saveDone$.getValue(); }
  setTitle(value: string) { this.title$.next(value); }
  getTitle(): string { return this.title$.getValue(); }

  create(data: Partial<IReceipts>): Observable<IResponse<IReceipts>> {
    return this.http.post<IResponse<IReceipts>>(`
    ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readAll(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<IPropertyReceipt>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<IPropertyReceipt>>(`
        ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS}/property_user/receipt?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  private filterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }
}
