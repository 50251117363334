import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportListService {

  private API_URI: string;
  

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getLastMonthClose(Module: ModuleBackend){
    return this.http.get(
        `${this.API_URI}/${Module}/close-month/validation`,
        this.headerComplement.complementHeader()
      );
  }

  sendingOTP(Module: ModuleBackend){
    return this.http.get(
      `${this.API_URI}/${Module}/otp/create`,
      this.headerComplement.complementHeader()
    );
  }

  closeMonth(Module: ModuleBackend, opt: any){
    return this.http.get(
      `${this.API_URI}/${Module}/close-manul/month/${opt}`,
      this.headerComplement.complementHeader()
    );
  }
}
