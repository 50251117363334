import { Component, Input, OnInit } from '@angular/core';
import { FcmRepo } from '@common/functions/fcm.repo';
import { ModalController } from '@ionic/angular';
import { NavigationApp } from '@navigation/*';
import { ModalNotificationPushComponent } from '../modal-notification-push/modal-notification-push.component';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  
  navigationApp = NavigationApp;
  notifications: any[] = [];
  visibleModal: boolean = false;
  inWeb: boolean = false;

  constructor(
    private fcmRepo: FcmRepo
  ){
    if(Capacitor.getPlatform() === 'web' && window.innerWidth > 768){
      this.inWeb = true;
    }
  }

  ngOnInit(){
    if(Capacitor.getPlatform() !== 'web'){
      this.getNotifications()
    }
  }

  ionViewDidEnter(){
    if(Capacitor.getPlatform() !== 'web'){
      this.getNotifications()
    }
  }

  getNotifications(){
    this.fcmRepo.getDeliveredNotifications().then((res: any) => {
      this.notifications = res && res.notifications? res.notifications : [];
      console.log('NOTIFICACIONES ENTREGADAS EN HEADER ', this.notifications)
    }).catch((error: any) => {
      console.log('ERROR AL OBTENER LAS NOTIFICACIONES RECIBIDAS ', error)
    })
  }

  goToWebWhatsapp() {
    window.open(environment.WAPP_LINK);
  }

  async showModalNotifPush(value: any){
    this.visibleModal = value;
    if(Capacitor.getPlatform() !== 'web'){
      this.getNotifications()
    } else {
      this.notifications = []
    }
  }
}
