<ion-header [translucent]="true" [ngClass]="{'ion-no-border': inWeb}">
  <ion-toolbar mode="md" [ngClass]="{'header-web': inWeb}">
    <ion-title color="primary" *ngIf="!inWeb">
      <ion-img src="assets/img/LogoName-Dark.svg" class="logo-cintuz"></ion-img>
    </ion-title>
    <ion-buttons slot="end">
  
      <ion-button (click)="goToWebWhatsapp()" class="btn-whatsapp" fill="clear">
        <ion-icon name="logo-whatsapp" color="medium" slot="icon-only"></ion-icon>
      </ion-button>

      <ion-button class="btn-notifications" fill="clear" (click)="showModalNotifPush(true)">
        <ion-badge color="danger" *ngIf="notifications && notifications.length > 0">1</ion-badge>
        <ion-icon name="notifications-outline" color="medium" slot="icon-only"></ion-icon>
      </ion-button>

      <ion-menu-button class="margin-left-md" slot="end" menuId="main-menu"></ion-menu-button>

    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <modal-notification-push 
    [visible]="visibleModal"
    [notifications]="notifications"
    (dismissModal)="showModalNotifPush($event)">
  </modal-notification-push>
</ion-content>
