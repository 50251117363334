import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, first } from 'rxjs';
import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend } from '@enums/index';
import { IClient, IErrorResponse, IResponse } from '@interfaces/index';
import { MessagesAlerts } from '@common/index';

@Injectable({
  providedIn: 'root'
})
export class ProfilePropertyUserService  {

  constructor(
    private http: HttpClient,
    private messagesAlerts: MessagesAlerts,
    private headerComplement: HttpComplement
  ) { }

  update(data: Partial<IClient>): Observable<IResponse<IClient>> {
    return this.http.post<IResponse<IClient>>(`
    ${environment.API_URL}/${ModuleBackend.AUTH_CLIENT}/profile_property_user`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
    .pipe(
      first()
    );
  }

  check_user(): Observable<IResponse<IClient>> {
    return this.http.get<IResponse<IClient>>(`
    ${environment.API_URL}/${ModuleBackend.AUTH_CLIENT}/check_user`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
    .pipe(
      first()
    );
  }

  updatePhoto(data: any): Observable<IResponse<IClient>> {
    return this.http.post<IResponse<IClient>>(`
    ${environment.API_URL}/${ModuleBackend.AUTH_CLIENT}/profile_photo`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
    .pipe(
      first()
    );
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }

}
