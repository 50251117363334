import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ModulesManagementService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getModulesGroup(Module: ModuleBackend, withModuleGroupConfigs?: boolean) {
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000&withModuleGroupConfigs=${withModuleGroupConfigs? withModuleGroupConfigs : false}`,
      this.headerComplement.complementHeader()
    );
  }
}
