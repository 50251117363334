import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { IErrorResponse } from '@interfaces/*';
import { MessagesAlerts } from '@common/index';
import { Observable, first } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class propertyOwnerService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private messagesAlerts: MessagesAlerts,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getAllUsers(Module: ModuleBackend, pagination: any, filter?: any){
    return this.http.get(
      `${this.API_URI}/${Module}/user/all?page=${pagination.page}&skip=${pagination.skip}${filter !== null? '&name='+filter : ''}`,
      this.headerComplement.complementHeader()
    );
  }

  getPropertyOwner(Module: any, id: number){
    return this.http.get(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getPropertyOwnerUser(Module: any, id: number){
    return this.http.get(
      `${this.API_URI}/${Module}/user/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  createPropertyOwner(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updatePropertyOwner(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updatePropertyOwnerUser(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/user/update/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  uploadBulk(file: File, compoundId: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('compoundId', compoundId.toString());

    return this.http.post<any>(`${environment.API_URL}/${ModuleBackend.PROPERTY_OWNER_CLIENT}/bulk_create/owners`,
    formData,
    { headers: this.headerComplement.getHeadersUploadFile() })
      .pipe(first());
  }

  downloadTemplate(compoundId: number): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/${ModuleBackend.PROPERTY_OWNER_CLIENT}/property-owner/download-template/${compoundId}`,
      { headers: this.headerComplement.getHeadersDownloadFile(), responseType: 'blob' }
    );
  }

  getUsersProperty(): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/property_user_permission_client/all_property_user_admin_v2`,
      this.headerComplement.complementHeader()
    );
  }

  getServices(): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/services_client`,
      this.headerComplement.complementHeader()
    );
  }

  saveProfile(data: any): Observable<any> {
    return this.http.post(
      `${environment.API_URL}/property_user_permission_client`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  getUsersControl(): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/user-controls`,
      this.headerComplement.complementHeader()
    );
  }  

  saveUserControl(data: any): Observable<any> {
    return this.http.post(
      `${environment.API_URL}/user-controls`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updateUserControl(data: any, id: any): Observable<any> {
    return this.http.patch(
      `${environment.API_URL}/user-controls/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  associateServices(data: any): Observable<any> {
    return this.http.post(
      `${environment.API_URL}/user-controls/associate/services`,
      data,
      this.headerComplement.complementHeader()
    )
  }

  deleteUserControl(id: any): Observable<any> {
    return this.http.delete(
      `${environment.API_URL}/user-controls/${id}`,
      this.headerComplement.complementHeader()
    )
  }

  getClientsUserControl(): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/user-controls/clients/all`,
      this.headerComplement.complementHeader()
    );
  } 

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }

}
