import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first } from 'rxjs';
import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IPagination, IResponse, ISurvey, ISurveyAnswer, ISurveyReport } from '@interfaces/index';


@Injectable({
  providedIn: 'root'
})
export class SurveyAnswerService {

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) { }

  create(data: Partial<ISurveyAnswer>): Observable<IResponse<ISurveyAnswer>> {
    return this.http.post<IResponse<ISurveyAnswer>>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY}/create`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readReportFromSurveyId(id: number): Observable<IResponse<ISurveyReport>> {
    return this.http.get<IResponse<ISurveyReport>>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY}/view-survey-report/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readSurveysAnsweredByUser(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<ISurvey>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<ISurvey>>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY}/findAll/surveyResponsesForClient?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  private filterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }
}
