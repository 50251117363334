import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, first } from 'rxjs';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { HttpComplement } from 'src/common/functions/http-complement';
import { IErrorResponse, ICrudResponse, IResponse } from '@interfaces/index';
import { inject } from '@angular/core';
import { IPagination } from '@interfaces/index';
import { MessagesAlerts, constants } from '@common/index';


export abstract class GenericCrudService<T> {
  protected http: HttpClient = inject(HttpClient);
  protected headerComplement: HttpComplement = inject(HttpComplement);
  protected messagesAlerts = inject(MessagesAlerts);

  constructor(private serviceModuleURL: ModuleBackend) { }

  protected create(data: Partial<T>): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(`
    ${environment.API_URL}/${this.serviceModuleURL}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  protected readOne(id: number): Observable<IResponse<T>> {
    return this.http.get<IResponse<T>>(`
      ${environment.API_URL}/${this.serviceModuleURL}/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  protected readAll(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<T>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<T>>(`
      ${environment.API_URL}/${this.serviceModuleURL}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  protected update(id: number, data: Partial<T>): Observable<IResponse<T>> {
    return this.http.patch<IResponse<T>>(`
    ${environment.API_URL}/${this.serviceModuleURL}/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  protected delete(id: number): Observable<IResponse<T>> {
    return this.http.delete<IResponse<T>>(`
    ${environment.API_URL}/${this.serviceModuleURL}/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  protected approve(data: Partial<T>): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(`
    ${environment.API_URL}/${this.serviceModuleURL}/approve`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  protected reject(data: Partial<T>): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(`
    ${environment.API_URL}/${this.serviceModuleURL}/reject`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  filterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }

  protected download(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}, type?: string): Observable<Blob> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get(`
      ${environment.API_URL}/${this.serviceModuleURL}/report/${type}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw(), responseType: 'blob' }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
}
