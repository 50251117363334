import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AliquoutService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getPropertiesWithAliquouts(clientId: number, compoundId?: number,) {
    return this.http.get(
      `${this.API_URI}/${ModuleBackend.PROPERTY_CLIENT}/with_aliquout?page=1&skip=99&clientId=${clientId}${compoundId? '&compoundId='+compoundId : ''}`,
      this.headerComplement.complementHeader()
    );
  }

  updateAliquout(id: number, data: any) {
    return this.http.patch(
      `${this.API_URI}/${ModuleBackend.ALIQUOTS_SCLIENT}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  saveAliquout(data: any) {
    return this.http.post(
      `${this.API_URI}/${ModuleBackend.ALIQUOTS_SCLIENT}`,
      data,
      this.headerComplement.complementHeader()
    );
  }
}
