import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './common';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { UrlTree } from '@angular/router';
import { IPagination } from '@common/components/pagination/interface';
import { IErrorResponse, IUser } from '@interfaces/*';
import { MessagesAlerts } from '@common/index';


@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private messagesAlerts: MessagesAlerts,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  create(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  getEmployee(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getEmployees(module: ModuleBackend, pagination: IPagination, filter?: any): Observable<boolean | UrlTree> | any {

    let contentFilter = "";
    if (filter) {
      filter.map((itFilter: any) => {
        if (itFilter.active &&  (itFilter.value !== null && itFilter.value !== '')) {
          contentFilter = contentFilter + `&${ itFilter.type }=${itFilter.value }`;
        }
      });
    }

    return this.http.get(`
      ${this.API_URI}/${module}?page=${pagination.currentPage}&skip=${pagination.skip}${ contentFilter ? contentFilter: ''}`,
      this.headerComplement.complementHeader()
    );
  }

  updateEmployee(module: ModuleBackend, id: number, data: any): Observable<boolean | UrlTree> | any {
    return this.http.patch(`
      ${this.API_URI}/${module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  deleteEmployee(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.delete(`
      ${this.API_URI}/${module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  deleteEmployeePhoto(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.delete(`
      ${this.API_URI}/${module}/photo/profile${id}`,
      this.headerComplement.complementHeader()
    );
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }
}
