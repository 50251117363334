/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
export const messages = {
  SESSION_EXPIRED: 'Su sesión ha expirado',
  PASS_ATTEMPTS: 'Supero el número de intentos, por favor espere unos segundos.',
  EXTEND_SESSION_QUESTION: '¿Desea extender su tiempo en la sesión?',
  APP_INIT_ERROR: 'Ha ocurrido un error, por favor recargue la página',
  VALIDATE_HASH_CODE: 'Por favor ingrese el código de seguridad enviado a su correo.',
  NEW_SEND_CODE: 'El código ha sido enviado nuevamente.',
  USER_AND_EMAIL_NOT_VERIFIED: 'Debe verificar su correo electrónico e identidad para acceder a este módulo.',
  USER_NOT_VERIFIED: 'Debe verificar su identidad en petroapp para acceder a este módulo.',
  EMAIL_NOT_VERIFIED: 'Debe verificar su correo electrónico para acceder a este módulo.',
  PREVENTIVE_LOCK: 'Se ha detectado actividad inusual en su cuenta. Para su reactivación escriba al administrador.',
  PASS_UPDATE: 'Su contraseña ha sido cambiada exitosamente',
  USER_EXISTS: 'El usuario ya existe',
  VERIFIED_CODE: 'Su código ha sido verificado correctamente',
  PASS_REQUEST: 'Existe una solicitud de cambio de usuario en proceso',
  SENDING_CODE: 'El código ha sido enviado al nuevo correo electrónico',
  SEND_EMAIL: 'Recibirá el correo electrónico en breves momentos',
  EMAIL_DONT_EXISTS: 'El correo electrónico ingresado no existe',
  ERROR_CODE_USER_UPDATE: 'Su código no es válido',
  ERROR_PROFILE_FILE_UPLOAD: 'El archivo excede el tamaño permitido',
  INVALID_PROFILE_FILE_UPLOAD: 'Formato de archivo inválido',
  ERROR_FILE: 'Ha ocurrido un error al intentar subir el archivo, por favor intentelo de nuevo',
  ERROR_FORM: 'Ha ocurrido un error al enviar el formulario',
  USER_CHANGE: 'Sus datos han sido cargados exitosamente. Recibirá una notificación cuando haya sido verificada su identidad',
  CODE_PHONE: 'El código ha sido enviado a su número de teléfono',
  INVALID_PIN: 'El PIN ingresado es incorrecto.',
  NO_AFFILIATES: 'No posee servicios afialiados.',
  INVALID_LENGTH_NUMBER_PHONE: 'El número de teléfono debe tener una longitud de 7 digitos.',
  INVALID_PATTERN_NUMBER_PHONE: 'El número de teléfono debe ser numérico.',
  INVALID_PATTERN_NUMBER_CARD: 'La tarjeta de acceso debe ser numérico.',
  NOT_NATURAL: 'El usuario debe ser natural para ingresar a este módulo.',
  NOT_ALIAS: 'Debe registrar su alias para acceder a este módulo.',
  SEND_CODE: 'El código ha sido enviado a su número de teléfono.',
  PHONE_UPDATED: 'Su teléfono ha sido registrado exitosamente',
  SEND_VERIFICATION_EMAIL: 'Se ha enviado un código para verificar su correo electrónico.',
  INVALID_CAPTCHA: 'Falló la validación del captcha',
  ERROR_UNEXPECTED: "Error inesperado, por favor vuelva a intentarlo.",
  BATERY_LEVEL: 'Su dispositivo tiene el nivel de bateria bajo.',
  FORMAT_INVALID_NUMBER_PHONE: 'Formato de número invalido, debe corresponder con (04XX) XXX XXXX. Intente de nuevamente.',
  NOT_CONNECTION: "Usted no posee internet, por favor compruebe su conexión.",
  INACTIVITY_TIME: "Su sesión expiró, debido al tiempo de inactividad.",
  SUCCESS_CHANGE_PASSWORD: "Cambio de contraseña exitosa.",
  ERROR_UNEXPECTED_2: "Se ha producido un error en su solicitud, por favor vuelva a intentarlo.",
  SIGNAL_FACEID_FRINGERPRINT: "El dispositivo no dispone sensor de huella o reconocimiento facial.",
  INFO_INVALID: "La información suministrada no es valida, por favor Verifique los datos.",
  INFO_INVALID_FINGERPRINT: "La información biometrica no es valida, por favor vuelva a intentarlo.",
  USER_INVALID_AUTH: "No puede solicitar un pago al mismo usuario.",
  NOTIFY_PERMISSION: "Para recibir notificaciones debe habilitarlas para esta aplicación.",
  NOTIFY_REGISTER_ERROR: "No se pudo registrar las notificaciones, favor cierre sesión e inicie nuevamente",
  RECHARGE_REJECTED: 'Rechazada',
  ERROR_CALCULATE_SERVICE: 'Error de calculos del servicio, intente más tarde',
  ERROR_SERVICE: 'Error',
  SESSION_FORCE_CLOSE: 'Sesión Cerrada',
  RETRY_REQUEST: 'Reintente',
  ERROR_INPUT_EMPTY: 'Ingrese todos los campos correctamente',
  FIELD_REQUIRED: 'Todos los campos son requeridos.',
  ERROR: '¡Ha ocurrido un error, intente nuevamente!',
  INFO_SUCCESS_LOAD: '¡Su información ha sido cargada con éxito!',
  SUCCESS_DELETE_TDC: '¡Tarjeta eliminada satisfactoriamente!',
  INFO_SUCCESS_REQUEST: "¡Su solicitud ha sido generada exitosamente!",
  ERROR_USER_NO_VERIFY: 'La dirección de billetera del usuario destino no está verificada.',
  REQUEST_CODE_NRO_TELF: "Introduzca código de verificación enviado a su nro. de teléfono móvil.",
};
