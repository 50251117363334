import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AssistantsService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getAssitantsByModule(Module: ModuleBackend, idModule: any) {
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000&moduleGroupId=${idModule}`,
      this.headerComplement.complementHeader()
    );
  }

  getAccounts(Module: ModuleBackend, moduleGroupId: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000&displayAll=true&withoutModuleGroup=true`,
      this.headerComplement.complementHeader()
    );
  }

  getAllAccounts(Module: ModuleBackend, withoutModuleGroup?: boolean){
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000&displayAll=true${withoutModuleGroup? '&withoutModuleGroup=true' : ""}`,
      this.headerComplement.complementHeader()
    );
  }

  getListTypeAssitants(Module: ModuleBackend, moduleGroupId: number){
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000&moduleGroupId=${moduleGroupId}`,
      this.headerComplement.complementHeader()
    );
  }

  createAssistant(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  editAssistant(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  deleteAssistant(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  updateStatus(Module: ModuleBackend, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/update_status/${id}`,
      {},
      this.headerComplement.complementHeader()
    );
  }
  
  syncAuxOwners(Module: ModuleBackend) {
    return this.http.post(
      `${this.API_URI}/${Module}/synchronize/propertyOwners`,
      null,
      this.headerComplement.complementHeader()
    );
  }

  syncAuxEmployess(Module: ModuleBackend) {
    return this.http.post(
      `${this.API_URI}/${Module}/synchronize/employees`,
      null,
      this.headerComplement.complementHeader()
    );
  }
}
