import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpComplement } from 'src/common/functions/http-complement';
import { ModuleBackend } from 'src/enums/modules-backend.enum';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  registerUser(Module: ModuleBackend, data: any){
    return this.http.post(
        `${this.API_URI}/${Module}/user/create`,
        data
    );
  }

  checkLink(Module: ModuleBackend, data: any){
    return this.http.get(
        `${this.API_URI}/${Module}/user/check?uniqueCode=${data.uniqueCode}&propertyOwnerId=${data.porpertyOwnerId}`
    );
  }
}
