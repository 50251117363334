import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { IBill, IResponse } from '@interfaces/common/Table types/IDocumentTypes';
import { HttpComplement } from '@common/functions/http-complement';


@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) { }

  readBillHistory(id: number): Observable<IResponse<IBill>> {
    return this.http.post<IResponse<IBill>>(
      `${environment.API_URL}/${ModuleBackend.BIILS_CLIENT}/get_bill_history`,
      { billId: id },
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }
}
