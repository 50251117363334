import { Injectable } from '@angular/core';
import { first, Observable, of } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { ICrudResponse, IDisbursementDetail, IPagination, IResponse } from '@interfaces/*';


@Injectable({
  providedIn: 'root'
})
export class GeneralReceiptService {

  private API_URI: string;
  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement,
  ) {
    this.API_URI = environment.API_URL;
  }

  getdAll(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<any>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<any>>(
      `${environment.API_URL}/${ModuleBackend.RECEIPT_CLIENT}/totalReceipt?${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first()
      );
  }

  getDisbursementReport(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<any>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<any>>(
      `${environment.API_URL}/${ModuleBackend.RECEIPT_CLIENT}/receipt?${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first()
      );
  }

  getDisbursementReportWithProperty(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<any>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<any>>(
      `${environment.API_URL}/${ModuleBackend.RECEIPT_CLIENT}/receiptWithAliquot?${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first()
      );
  }

  getDisbursementDetails(extraKeys: {} = {}): Observable<IResponse<IDisbursementDetail>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<IResponse<IDisbursementDetail>>(
      `${environment.API_URL}/${ModuleBackend.RECEIPT_CLIENT}/details?${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  getOwnerReceiptHistory(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<any>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<any>>(
      `${environment.API_URL}/receipt?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first()
      );
  }

  getGeneralReceiptHistory(pagination: Pick<IPagination, "currentPage" | "skip">): Observable<ICrudResponse<any>> {
    return this.http.get<ICrudResponse<any>>(
      `${environment.API_URL}/voucherClient?page=${pagination.currentPage}&skip=${pagination.skip}&type=2,3`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first()
      );
  }

  filterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }
}
