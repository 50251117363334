import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { constants } from '@common/index';

@Component({
  selector: 'app-sucessfull-screen',
  templateUrl: './sucessfull-screen.component.html',
  styleUrls: ['./sucessfull-screen.component.scss'],
})
export class SucessfullScreenComponent implements OnChanges {
  @Input() title: string;
  @Input() saveDone: boolean;
  @Output() saveDoneChange = new EventEmitter<boolean>();
  sucessfullIcon = "/assets/icon/sucessfull/Sucessfull_Icon_Animated.svg";
  constructor() {
    this.title = "¡Cambios aplicados!";
    this.saveDone = false;
  }
  ngOnChanges(changes: SimpleChanges): void { if (this.saveDone) setTimeout(() => this.saveDoneChange.emit(false), constants.SUCESSFULL_SCREEN_TIMEOUT); }
}
