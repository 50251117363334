import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IJournal, IPagination, IResponse } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';


@Injectable({
  providedIn: 'root'
})
export class JournalService extends GenericCrudService<IJournal> {

  constructor() { super(ModuleBackend.JOURNAL_CLIENT); }

  override readOne(id: number): Observable<IResponse<IJournal>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {}): Observable<ICrudResponse<IJournal>> { return super.readAll(pagination, extraKeys); }
}
