import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first } from 'rxjs';
import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend } from '@enums/index';
import { IResponse, ITenant } from '@interfaces/index';


@Injectable({
  providedIn: 'root'
})
export class TenantSettingsService {

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) { }

  create(data: Partial<ITenant>): Observable<IResponse<ITenant>> {
    return this.http.post<IResponse<ITenant>>(`
    ${environment.API_URL}/${ModuleBackend.PROPERTY_OWNER_CLIENT}/user/create/request/tenant`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readAll(): Observable<IResponse<ITenant[]>> {
    return this.http.get<IResponse<ITenant[]>>(`
    ${environment.API_URL}/${ModuleBackend.PROPERTY_OWNER_CLIENT}/display/usersTenantBelongsProperty`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  delete(propertyUserId: number, propertyId: number) {
    return this.http.delete<IResponse<ITenant>>(`
    ${environment.API_URL}/${ModuleBackend.PROPERTY_CLIENT}/delete/userBelongsProperty/${propertyUserId}/${propertyId}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }
}
