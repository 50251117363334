import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpComplement } from '@common/functions/http-complement';
import { UtilsFunctions } from '@common/functions/utils';
import { ModuleBackend, ServiceName, TypePropertyUser } from '@enums/index';
import { environment } from '@environments/environment';
import { IResponse } from '@interfaces/*';
import { RadioGroupChangeEventDetail } from '@ionic/angular';
import { NavigationApp } from '@navigation/*';
import { BehaviorSubject, Observable, first } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaymentsAndReceiptsService {
  private radioValue$: BehaviorSubject<TypePropertyUser>;
  private doublePermission$: BehaviorSubject<boolean | null>;
  private forceHide$: BehaviorSubject<boolean>;

  constructor(
    private utilsFunctions: UtilsFunctions,
    private router: Router,
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.radioValue$ = new BehaviorSubject<TypePropertyUser>(TypePropertyUser.ADMINISTRADOR);
    this.doublePermission$ = new BehaviorSubject<boolean | null>(false);
    this.forceHide$ = new BehaviorSubject<boolean>(false);
    this.initialValues();
  }

  initialValues() {
    this.setRadioValue(TypePropertyUser.ADMINISTRADOR);
    this.doublePermission$.next(this.utilsFunctions.thisUserIsAdmin() && (this.utilsFunctions.thisUserIsProperty() || this.utilsFunctions.thisUserIsTenant()));
    this.setForceHide(false);
  }

  setRadioValue(typePropertyUser: TypePropertyUser) { this.radioValue$.next(typePropertyUser); }
  getRadioValue(): TypePropertyUser { return this.radioValue$.getValue(); }

  getDoublePermission(): boolean | null { return this.doublePermission$.getValue(); }

  setForceHide(forceHide: boolean) { this.forceHide$.next(forceHide); }
  getForceHide(): boolean | null { return this.forceHide$.getValue(); }

  radioChange({ detail }: any) {
    const radioGroupChangeEventDetail: RadioGroupChangeEventDetail<TypePropertyUser> = detail;
    this.setRadioValue(radioGroupChangeEventDetail.value);
    if (radioGroupChangeEventDetail.value !== TypePropertyUser.ADMINISTRADOR) {
      // if (this.router.url.includes(NavigationApp.ADMIN_RECEIPTS_HISTORY)) this.router.navigate(['/' + NavigationApp.DASHBOARD + '/' + NavigationApp.PAYMENTS_AND_RECEIPTS + '/' + NavigationApp.PAYMENTS_OWNER + '/' + NavigationApp.BILLS]);
      // else this.router.navigate(['/' + NavigationApp.DASHBOARD + '/' + NavigationApp.PAYMENTS_AND_RECEIPTS + '/' + NavigationApp.PAYMENTS_OWNER]);
      this.router.navigate(['/' + NavigationApp.DASHBOARD + '/' + NavigationApp.PAYMENTS_AND_RECEIPTS + '/' + NavigationApp.PAYMENTS_OWNER]);
    } else
      this.router.navigate(['/' + NavigationApp.DASHBOARD + '/' + NavigationApp.PAYMENTS_AND_RECEIPTS]);
  }

  checkPlanService(serviceName: ServiceName): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`
      ${environment.API_URL}/${ModuleBackend.AUTH_CLIENT}/check_plan_has_service`,
      { name: serviceName },
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }
}
