import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first } from 'rxjs';
import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend, SurveyStatus } from '@enums/index';
import { ICrudResponse, IPagination, IQuestion, IQuestionResponse, IResponse, ISurvey } from '@interfaces/index';


@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) { }

  create(data: Partial<ISurvey>): Observable<IResponse<ISurvey>> {
    return this.http.post<IResponse<ISurvey>>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/createSurvey`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readOne(id: number): Observable<ISurvey> {
    return this.http.get<ISurvey>(`
      ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/findOne/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readAll(pagination: Pick<IPagination, "currentPage" | "skip">, isPropertyAdmin: boolean = false, extraKeys: {} = {}): Observable<ICrudResponse<ISurvey>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<ISurvey>>(`
        ${environment.API_URL}/${isPropertyAdmin ? ModuleBackend.SURVEY_ADMIN : ModuleBackend.SURVEY}/${isPropertyAdmin ? 'findAll' : 'findAllSurveyAvailable'}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  update(id: number, data: Partial<ISurvey>): Observable<ISurvey> {
    return this.http.patch<ISurvey>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/updateSurvey/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  updateStatus(id: number, status: SurveyStatus): Observable<ISurvey> {
    return this.http.patch<ISurvey>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/changeStatus/${id}/${status}`,
      null,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  delete(id: number): Observable<ISurvey> {
    return this.http.delete<ISurvey>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/deleteSurvey/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  createQuestions(data: { surveyId: number, questions: Partial<IQuestion[]> }): Observable<IQuestion> {
    return this.http.post<IQuestion>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/createManyQuestion`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  updateQuestions(id: number, data: Partial<ISurvey>): Observable<IQuestion> {
    return this.http.patch<IQuestion>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/updateQuestion/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  deleteQuestion(id: number): Observable<IQuestion> {
    return this.http.delete<IQuestion>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/removeQuestion/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  createQuestionResponses(data: { questionId: number, responses: Partial<IQuestionResponse[]> }): Observable<IQuestionResponse> {
    return this.http.post<IQuestionResponse>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/createManyResponse`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  updateQuestionResponses(id: number, data: Partial<IQuestionResponse>): Observable<IQuestionResponse> {
    return this.http.patch<IQuestionResponse>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/updateResponses/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  deleteQuestionResponse(id: number): Observable<IQuestionResponse> {
    return this.http.delete<IQuestionResponse>(`
    ${environment.API_URL}/${ModuleBackend.SURVEY_ADMIN}/removeResponse/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  private filterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }
}
