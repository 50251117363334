import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { Observable, finalize, of, catchError, tap } from 'rxjs';
import { NavigationApp } from '@navigation/*';
import { AuthType } from '@enums/authTypeUser.enum';
import { AuthService, HomeService, LocalStorageService, propertyOwnerService } from 'src/services';
import { ModuleBackend } from '@enums/modules-backend.enum';
import { IErrorResponse } from '@interfaces/*';
import { MessagesAlerts } from '@common/index';


@Injectable({
  providedIn: 'root'
})
export class LoadPermissionsResolver implements Resolve<Observable<any>> {

  listProperties: any[] = [];
  listItems: any[] = [];

  constructor(
    public router: Router,
    private authService: AuthService,
    private homeService: HomeService,
    private loadingService: LoadingService,
    private _localStorage: LocalStorageService,
    private messagesAlerts: MessagesAlerts,
    private propertyOwnerService: propertyOwnerService
  ) { }

  resolve(): Observable<any> {
    this.loadingService.showLoading();

    const userSession = this._localStorage.getItem('userSession');

    if (userSession && userSession.data && userSession.data.token) {

      if(!userSession.data?.userControlToken) {
        if (userSession.data.authType !== AuthType.client) {
          this.listProperties = userSession.data.propertyOwners;
  
          if (!userSession.data.infoPropertySelected && this.listProperties && this.listProperties.length === 1) {
            return this.homeService.selectProperty(ModuleBackend.AUTH_CLIENT, this.listProperties[0].propertyId).pipe(
              tap((res: any) => {
                if (res.statusCode === 200) {
                  this.router.navigate(['/dashboard/home']);
                } else {
                  this.onError(res);
                  this.router.navigate(['/dashboard/home/select-property']);
                }
              }),
              catchError((error: any) => {
                this.onError(error.error);
                this.router.navigate(['/dashboard/home/select-property']);
                return of(null);
              }),
              finalize(() => this.loadingService.hideLoading())
            );
          } else {
            return this.authService.isAuthenticated().pipe(
              finalize(() => this.loadingService.hideLoading())
            );
          }
        } else {
          return this.authService.isAuthenticated().pipe(
            finalize(() => this.loadingService.hideLoading())
          );
        }
  
      }

      
      if(userSession.data?.userControlToken) {
        return this.propertyOwnerService.getClientsUserControl().pipe(
          tap((res: any) => {
            if (res.statusCode === 200) {
              if (res.data.length) {
                this.listItems = res.data[0].clients;
                
                return this.homeService.selectClient(ModuleBackend.USER_CONTROL, this.listItems[0]?.client?.id).pipe(
                  tap((res: any) => {
                    if (res.statusCode === 200) {
                      this.router.navigate(['/dashboard/home']);
                    } else {
                      this.onError(res);
                      this.router.navigate(['/dashboard/home/select-client']);
                    }
            
                    return this.authService.isAuthenticated().pipe(
                      finalize(() => this.loadingService.hideLoading())
                    );
                  }),
                  catchError((error: any) => {
                    this.onError(error.error);
                    this.router.navigate(['/dashboard/home/select-client']);
                    return of(null);
                  }),
                  finalize(() => this.loadingService.hideLoading())
                );

              } else {
                return this.authService.isAuthenticated().pipe(
                  finalize(() => this.loadingService.hideLoading())
                );
              }
            } else {
              this.onError(res);
              this.router.navigate(['/dashboard/home/select-client']);

              return this.authService.isAuthenticated().pipe(
                finalize(() => this.loadingService.hideLoading())
              );
            }
          }),
          catchError((error: any) => {
            this.onError(error.error);
            this.router.navigate(['/dashboard/home/select-client']);
            return of(null);
          }),
          finalize(() => this.loadingService.hideLoading())
        );
        
      }

      return this.authService.isAuthenticated().pipe(
        finalize(() => this.loadingService.hideLoading())
      );

      
    } else {
      this.loadingService.hideLoading();
      this.router.navigate([NavigationApp.LOGIN]);
      return of(null);
    }
  }

  sendSelectProperty(propertyId: any) {
    return this.homeService.selectProperty(ModuleBackend.AUTH_CLIENT, propertyId)
    .subscribe((res: any) => {

      if (res.statusCode === 200) {
        this.router.navigate(['/dashboard/home']);
      } else {
        this.onError(res);
        this.router.navigate(['/dashboard/home/select-property']);
      }

      return this.authService.isAuthenticated().pipe(
        finalize(() => this.loadingService.hideLoading())
      );
    }, (error: any) => {
      this.onError(error.error);
      this.router.navigate(['/dashboard/home/select-property']);

      return this.authService.isAuthenticated().pipe(
        finalize(() => this.loadingService.hideLoading())
      );
    })
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "tertiary-four", 1500, 'bottom'); }

}
