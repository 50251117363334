import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessagesAlerts } from '@common/index';
import { IErrorResponse } from '@interfaces/*';
import { ModalController } from '@ionic/angular';
import { CompoundService } from '@services/index';
import { ModalUploadBulkComponent } from '../modal-upload-bulk/modal-upload-bulk.component';
import { Filesystem, Directory } from '@capacitor/filesystem';


@Component({
  selector: 'app-upload-bulk-data',
  templateUrl: './upload-bulk-data.component.html',
  styleUrls: ['./upload-bulk-data.component.scss'],
})
export class UploadBulkDataComponent  implements OnInit {

  loading: boolean = false;
  /**  SALIDA EVENTO UPLOAD BULK */
  @Output() uploadBulkEv = new EventEmitter<any>();

  constructor(
    private modalCtrl: ModalController,
    private compoundService: CompoundService,
    private messagesAlerts: MessagesAlerts,
  ) { }

  ngOnInit() {}

  async confirmUpload(){
    const modal = await this.modalCtrl.create({
      component: ModalUploadBulkComponent,
      componentProps: {
        title: 'Cargar Archivo',
        detailElement: '¡Debe cargar un archivo!',
        action: 'Cargar',
      },
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'modal-confirm-delete',
    })

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if(data.role === true){
      this.upload(data.file)
    }
  }
  upload(event: any) {
    const files = event.detail.event.target.files;
    const file = files[0];

    this.loading = true;
    this.compoundService.uploadBulk(file).subscribe((res: any) => {
      if(res.statusCode === 200){
        this.messagesAlerts.openToast('Archivo cargado correctamente.', "tertiary-two");
        this.loading = false;
      } else {
        this.onError(res.ErrorResponse)
        this.loading = false;
      }
    }, (error: any) => {
      this.loading = false;
      this.onError(error.error)
    })
  }

  uploadBulk() {
    this.uploadBulkEv.emit(true);
  }

  downloadTemplate() {
    this.messagesAlerts.openToast('Funcionalidad en proceso.', "tertiary-two");
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger"); }

}
