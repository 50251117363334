import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from 'src/interceptors/HttpError.interceptor';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonComponentsModule } from '@common/components/common-components';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { MaskitoModule } from '@maskito/angular';
// Import Firebase
import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";
import { firebaseConfig } from './../firebase-config';

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgOtpInputModule,
    MaskitoModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CommonComponentsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "es" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
