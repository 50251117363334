import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { GenericCrudService } from 'src/common/classes/generic-crud.service';
import { IPagination } from 'src/common/components/pagination/interface';
import { ICrudResponse, ISisterCompounds } from '@interfaces/index';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CompoundService extends GenericCrudService<any> {
  compound$: BehaviorSubject<any>;

  constructor() {
    super(ModuleBackend.COMPOUND_CLIENT);
    this.compound$ = new BehaviorSubject<any>(undefined);
  }

  override create(data: Partial<any>): Observable<any> { return super.create(data); }
  override readOne(id: number): Observable<any> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {}): Observable<ICrudResponse<any>> { return super.readAll(pagination, extraKeys); }
  override update(id: number, data: Partial<any>): Observable<any> { return super.update(id, data); }
  override delete(id: number): Observable<any> { return super.delete(id); }

  readAllSisterCompounds(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} = {}): Observable<ICrudResponse<ISisterCompounds>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<ISisterCompounds>>(`
      ${environment.API_URL}/${ModuleBackend.COMPOUND_CLIENT}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  uploadBulk(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`${environment.API_URL}/${ModuleBackend.COMPOUND_CLIENT}/bulk_create`, 
    formData,
    { headers: this.headerComplement.getHeadersUploadFile() })
      .pipe(first());
  }

  downloadTemplate(clientid: number): Observable<any> { 
      return this.http.get(
        `${environment.API_URL}/${ModuleBackend.COMPOUND_CLIENT}/bulk_create/download-template/${clientid}`,
        { headers: this.headerComplement.getHeadersDownloadFile(), responseType: 'blob' }
      );
  }
}
