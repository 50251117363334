import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { LocalStorageService } from './common';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private API_URI: string;


  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getDataHome(Module: ModuleBackend) {
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000`,
      this.headerComplement.complementHeader()
    );
  }

  getDataHomeProperty(Module: ModuleBackend) {
    return this.http.get(
      //Module = receipt
      `${this.API_URI}/${Module}/dashboard`,
      this.headerComplement.complementHeader()
    );
  }

  getNumbersByCompound(Module: ModuleBackend, id: any) {
    return this.http.get(
      `${this.API_URI}/${Module}/compound-count/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllNumbersByCompound(Module: ModuleBackend, id: any) {
    return this.http.get(
      `${this.API_URI}/${Module}/summary/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  selectProperty(Module: ModuleBackend, propertyId: any) {
    return this.http.get(
      `${this.API_URI}/${Module}/select_property/${propertyId}`,
      this.headerComplement.complementHeader()
    ).pipe(
      map((userSession: any) => {
        if (userSession.statusCode == 200) {
          let tempSessionUser = this._localStorage.getItem('userSession');
          tempSessionUser.data.menu = userSession.data.menu;
          tempSessionUser.data.propertyId = propertyId;
          tempSessionUser.data.infoPropertySelected = {
            role: userSession.data.role,
            compound: userSession.data.compound,
            client: userSession.data.client,
            clientCurrencies: userSession.data.clientCurrencies
          }
          localStorage.setItem('userSession', JSON.stringify(tempSessionUser));
        }
        return userSession as any;
      })
    );
  }

  selectClient(Module: ModuleBackend, clientId: any) {
    return this.http.post(
      `${this.API_URI}/${Module}/clients/users`,
      { clientId : clientId },
      this.headerComplement.complementHeader(),
    ).pipe(
      map((userSession: any) => {
        if (userSession.statusCode == 200) {
          let tempSessionUser = this._localStorage.getItem('userSession');
          tempSessionUser.data.menu = userSession.data.menu;
          tempSessionUser.data.clientId = clientId;
          // tempSessionUser.data.infoPropertySelected = {
          //   role: userSession.data.role,
          //   compound: userSession.data.compound,
          //   client: userSession.data.client,
          //   clientCurrencies: userSession.data.clientCurrencies
          // }

          localStorage.setItem('userSession', JSON.stringify(tempSessionUser));
        }
        return userSession as any;
      })
    );
  }

  checkBillStatus() {
    return this.http.get(
      `${this.API_URI}/${ModuleBackend.AUTH_CLIENT}/check_bill_status`,
      this.headerComplement.complementHeader()
    );
  }
}
