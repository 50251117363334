import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-select-date',
  templateUrl: './modalSelectDate.component.html',
  styleUrls: ['./modalSelectDate.component.scss'],
})
export class ModalSelectDateComponent {
  @Input() icon!: string;
  @Input() textBtn!: any;
  @Input() title!: string;
  @Input() subtitle!: any;
  @Input() classContent!: any;
  @Input() classAction0!: any;
  @Input() classAction1!: any;
  @Input() classRowAction!: any;
  @Input() actionButton!: boolean;

  private dataSubject = new Subject<{ startDate: string, endDate: string }>();
  data$ = this.dataSubject.asObservable();
  loading: boolean = false;
  form!: FormGroup;
  dateNow: string = new Date(Date.now()).toISOString();
  dateSelected!: any;
  openModalDate: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController
  ) {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      startDate: [this.dateNow, [Validators.required]],
      endDate: [this.dateNow, [Validators.required]]
    });
  }

  SelectDate(ev: any) {
    const date = `${new Date(ev.detail.value).getDate() < 10 ? '0' + new Date(ev.detail.value).getDate() : new Date(ev.detail.value).getDate()}-${((new Date(ev.detail.value).getMonth() + 1) < 10) ? '0' + (new Date(ev.detail.value).getMonth() + 1) : new Date(ev.detail.value).getMonth() + 1}-${new Date(ev.detail.value).getFullYear()}`;
    this.dateSelected = new Date(ev.detail.value).toISOString();
    this.form.controls["date"].setValue(date);
    this.openModalDate = false;
  }

  EmitAction(name: any): any {
    if (this.form.valid) {
      return this.modalController.dismiss({
        startDate: this.form.value.startDate,
        endDate: this.form.value.endDate
      }, 'confirm');
    }
  }

  Close() {
    this.modalController.dismiss();
  }
}
