import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, finalize, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { HttpComplement } from 'src/common/functions/http-complement';
import { MessagesAlerts } from 'src/common/functions/messages-alerts';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { LocalStorageService } from './local-storage.service';
import { NavigationApp } from 'src/navegation';
import { UtilsFunctions } from '@common/functions/utils';
import { MessagesService } from './messages.service';
import { IClient, IResponse } from '@interfaces/*'
import { AuthType } from '@enums/authTypeUser.enum';
import { LoadingService } from 'src/services';

interface JwtResponseI {
  email: string,
  id: number,
  token: string,
}

interface UserLoginI {
  email: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  API_URI: string = environment.API_URL
  success: Boolean = false
  message: string = '';
  private user$ = new BehaviorSubject<any | null>(null);
  private _isLoggedIn = false;
  private _userSession = {} as any;
  userSessionObservable$: Subject<any> = new Subject<any>();
  loginObservable: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    public utilsFunctions: UtilsFunctions,
    private loadingService: LoadingService,
    private headerComplement: HttpComplement,
    private messagesAlerts: MessagesAlerts,
    private _messagesService: MessagesService,
    private _localStorage: LocalStorageService,
  ) {
    this._userSession = JSON.parse(localStorage.getItem('userSession')!) as any;
    this._setUserSession(this._userSession);
  }

  get userAuthenticated$(): Observable<any> {
    return this.user$.asObservable();
  }

  getUser(): any {
    return this._userSession;
  }


  getToken(): string {
    return this._userSession.token;
  }

  /**
  * @public @method _setUserSession()
  * @param user
  */
  public _setUserSession(userSession: any, saveFingerprint?: boolean): void {
    if (userSession) {

      this._isLoggedIn = true;
      this._userSession = Object.assign({}, { ...userSession });
      localStorage.setItem('userSession', JSON.stringify(this._userSession));

      // if (
      //   (localStorage.getItem('sessionFingerPrintAuth') || saveFingerprint) &&
      //   this._localStorage.getItem('userSession') &&
      //   this._localStorage.getItem('userSession').data &&
      //   this._localStorage.getItem('sessionFingerPrintAuth').data &&
      //   this._localStorage.getItem('sessionFingerPrintAuth').data.userEmail === this._localStorage.getItem('userSession').data.userEmail
      // ) {
      //   localStorage.setItem('sessionFingerPrintAuth', JSON.stringify(this._userSession.data));
      // }

      let tempSessionUser = this._localStorage.getItem('userSession');
      if (saveFingerprint) localStorage.setItem('sessionFingerPrintAuth', JSON.stringify(tempSessionUser.data));
      else localStorage.removeItem('sessionFingerPrintAuth');

    }
  }

  setUserAuthenticated(user: any) {
    this.user$.next(user);
  }

  login(authData: any): Observable<JwtResponseI | void> {
    return this.http.post<JwtResponseI>(`
      ${this.API_URI}/${ModuleBackend.AUTH_CLIENT}/login`,
      {
        ...authData
      }
    )
      .pipe(
        map((userSession: any) => {
          if (userSession.statusCode == 200) {
            this._setUserSession(userSession as any, (userSession.data.fingerPrint || authData.authType == 2) ? true : false);
            // if (userSession && userSession.data && userSession.data.dashboard && userSession.data.dashboard.urlActive) await this.routeRediref();
          }
          return userSession as any;
        })
      );
  }


  logout(): Observable<JwtResponseI | boolean | any> {
    return this.http.post<JwtResponseI>(`
      ${this.API_URI}/${ModuleBackend.AUTH_CLIENT}/logout`,
      {},
      this.headerComplement.complementHeader()
    )
      .pipe(
        map(
          (res: any) => {
            this._localStorage.deleteItem('userSession');
            this.router.navigate([NavigationApp.LOGIN], this.headerComplement.extrasNavigation());
            this._messagesService.hideLoading();
            return of(false);
          }
        ),
        catchError(
          (err) => { //Actualmente devuelve un string "cerrar sesion 1"
            this._localStorage.deleteItem('userSession');
            this.router.navigate([NavigationApp.LOGIN], this.headerComplement.extrasNavigation());
            this._messagesService.hideLoading();
            return of(false);
          }
        )
      )
  }

  public isAuthenticated(): Observable<boolean | UrlTree> | any {
    if (this._localStorage.getItem('userSession') !== null && this._localStorage.getItem('userSession').data && this._localStorage.getItem('userSession').data.token) {

      return this.http.get(
        `${this.API_URI}/${ModuleBackend.AUTH_CLIENT}/check_status`,
        this.headerComplement.complementHeader()
      )
        .pipe(
          map(
            (res: any) => {

                if (( (environment.VERSION_APP != res.data?.currentAppVersion?.version) || res.data?.currentAppVersion?.maintenance) && this.utilsFunctions.verifyMobile()) {
                  this.router.navigate([NavigationApp.STATUS_APP]);
                  return of(false);
                }

                if (res.data.currentAppVersion.maintenance) {
                  this.router.navigate([NavigationApp.STATUS_APP]);
                  return of(false);
                }

                if (res.statusCode != 200) {
                  localStorage.removeItem('userSession');
                  this.router.navigate([NavigationApp.LOGIN]);
                  return of(false);
                }

                if (res && res.data && (res.data.firstAccess || this._localStorage.getItem('userSession').data.kycVerification?.kyc === null || this._localStorage.getItem('userSession').data.clientRequireKyc === true)) {
                  if(this._localStorage.getItem('userSession').data.authType === AuthType.client)
                    this.router.navigate([NavigationApp.FIRST_ACCESS]);
                  else if(this._localStorage.getItem('userSession').data.authType === AuthType.control) {

                    if (
                      this._localStorage.getItem('userSession') && this._localStorage.getItem('userSession').data
                    ) {

                      let tempSessionUser: any = this._localStorage.getItem('userSession');
                      tempSessionUser.data.menu = res?.data?.menu;
                      tempSessionUser.data.userControlToken = res?.data?.userControlToken;
                      tempSessionUser.data.propertyId = res?.data?.userControlToken[0]?.propertyId;

                      if (res?.data?.clients[0]?.client) {
                        res.data.clients[0].client.address = res?.data?.clients[0]?.client?.physicalAddress;
                        res.data.clients[0].client.name = res?.data?.clients[0]?.client?.fullName;
                      }
                      
                      tempSessionUser.data.infoPropertySelected = {
                        role: res?.data?.propertyUserRoles,
                        compound: res?.data?.compound,
                        client: res?.data?.clients[0]?.client,
                        clientCurrencies: res?.data?.clientCurrencies
                      }
                      localStorage.setItem('userSession', JSON.stringify(tempSessionUser));
                    }

                    return true;
                  } else
                    this.router.navigate([NavigationApp.VALIDATE_ACCESS]);
                  return false;
                } else {
                  return true;
                }

            }
          ),
          catchError(
            (err) => {
              localStorage.removeItem('userSession');
              this.router.navigate([NavigationApp.LOGIN]);
              return of(false);
            }
          ),
        )
    } else {
      // Catched in checkguard
      console.log('dffff Catched in checkguard');
      return of(true);
    }
  }


  public getUserAutenticated(): Observable<any | UrlTree> | any {
    return this.http.get(
      `${this.API_URI}/${ModuleBackend.AUTH}/check-status`,
      this.headerComplement.complementHeader()
    )
      .pipe(
        map(
          (res: any) => {
            this.setUserAuthenticated(res);
            return res;
          }
        )
      )
  }

  getUserAuthenticatedClient(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(
      `${this.API_URI}/${ModuleBackend.AUTH_CLIENT}/check_status`,
      { headers: this.headerComplement.complementHeaderRaw() }
    );
  }

  public changePassword(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
    ${this.API_URI}/${module}/reset_password`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  /**
   * @method autheticateFingerPrint()
   * @param user {IAuthFingerprintDTO}
  **/
  async autheticateFingerPrint(data: any): Promise<any> {

    return this.http.post(`
      ${this.API_URI}/${ModuleBackend.AUTH_CLIENT}/refresh_token`,
      {
        ...data
      },
      this.headerComplement.complementHeader()
    ).pipe(
      map(async (userSession: any) => {
        if (userSession.statusCode === 200) {
          this._setUserSession(userSession as any, (userSession.data.fingerPrint || data.authType == 2) ? true : false);
        }
        return userSession;
      })
    ).toPromise();
  }
}
