import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LocalStorageService } from '@services/common';
import { LoadingService } from '@services/loading.service';
import { environment } from 'src/environments/environment';
import { NavigationApp } from 'src/navegation';
import { Location } from '@angular/common';
import { ProfileClientService } from '@services/profile-client.service';
import { UtilsFunctions } from '@common/functions/utils';
import { Observable, switchMap, of, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusAppGuard implements CanActivate {

  constructor(
    public router: Router,
    private location: Location,
    public utilsFunctions: UtilsFunctions,
    private loadingService: LoadingService,
    private profileClientService: ProfileClientService,
    private _localStorage: LocalStorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    // consultar endpoint de version app
    this.loadingService.showLoading();
    return this.profileClientService.readCurrentVersion().pipe(
      switchMap((res: any) => {
        if ((environment.VERSION_APP === res.data.version) && !res.data?.maintenance) {
          // la misma version pero no esta en mantenimiento.

          if (state.url.includes(NavigationApp.STATUS_APP)) {
            // estoy en la pagina de control de versionamiento.
            // entonces ir a login.

            this.router.navigate([NavigationApp.LOGIN]);
            return of(false);
          } else {

            // si es una ruta distinta, dar paso a la ruta siguiente. Dentro de app.

            return of(true);
          }
        } else {

          // si es distinta la version y es web.
          if (!this.utilsFunctions.verifyMobile()) {

            // si estas en la ruta de control de versionamiento y no estas en mantenimiento.
            // entonces mover a login.
            if (state.url.includes(NavigationApp.STATUS_APP) && !res.data?.maintenance) {
              this.router.navigate([NavigationApp.LOGIN]);
              return of(false);
            } else {

              if (!state.url.includes(NavigationApp.STATUS_APP) && res.data?.maintenance) {
                // si no estas en la ruta y estas mantenimiento y la pagina de versionamiento.
                this.router.navigate([NavigationApp.STATUS_APP]);
                return of(false);
              }

              // si estas en la ruta de versinamiento o no estas mantenimiento.
              return of(true);
            }
          }

          this.router.navigate([NavigationApp.STATUS_APP]);
          return of(false);
        }
      }),
      finalize(() => this.loadingService.hideLoading())
    );
  }
}
