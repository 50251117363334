import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StatusNotificationEnum } from '@enums/index';
import { StatusNotificationEnumColor } from '@enums/statusNotificationEnum.enum';
import { ModalController, AnimationController } from '@ionic/angular';
import { TableTypesService } from '@services/common';
import { NotificationsService } from '@services/notifications.services';

@Component({
  selector: 'item-notification',
  templateUrl: './item-notification.component.html',
  styleUrls: ['./item-notification.component.scss'],
})
export class ItemNotificationComponent  implements OnInit {

  @Input() item: any;
  @Input() listTypesNotif: any;
  @Input() showDescription: boolean = true;
  @Input() showButtonActions: boolean = false;
  @Input() selected: boolean = false;
  @Output() clickItem: EventEmitter<boolean> = new EventEmitter();
  @Output() clickItemFiles: EventEmitter<boolean> = new EventEmitter();
  @Output() clickEditItem: EventEmitter<boolean> = new EventEmitter();
  @Output() clickDeleteItem: EventEmitter<boolean> = new EventEmitter();

  isModalOpen: boolean;

  typeNotifEnum = [{
    target: 'Informativo',
    icon: 'newspaper'
  }, {
    target: 'Financiero',
    icon: 'analytics'
  }, {
    target: "Recordatorio",
    icon: "alarm-outline"
  }, {
    target: 'Entretenimiento',
    icon: 'game-controller'
  }, {
    target: 'Culturales',
    icon: 'brush-outline'
  }];
  // listTypesNotif: any[] = [];
  statusColor = StatusNotificationEnumColor

  constructor(
    private tablesType: TableTypesService,
    private animationCtrl: AnimationController,
    private modalCtrl: ModalController,
  ) {
    // this.tablesType.readAllTypesNotifications().subscribe((res:  any) => {
    //   this.listTypesNotif = res
    // });
    this.isModalOpen = false;
    console.log('thissffdsf', this.showDescription);

  }

  ngOnInit() {
    this.isModalOpen = false;
  }

  getIcon(target: any){
    return this.typeNotifEnum.filter(el => el.target.toLowerCase() === target.toLowerCase())[0]?.icon? this.typeNotifEnum.filter(el => el.target.toLowerCase() === target.toLowerCase())[0]?.icon : 'alarm-outline'
  }

  getTypeNotif(type: any){
    if(this.listTypesNotif && this.listTypesNotif.length > 0){
      return this.listTypesNotif.filter((el: any) => el.id === type)[0]?.name
    } else return ''
  }

  getIconByType(type: any){
    if(this.listTypesNotif && this.listTypesNotif.length > 0){
      return this.listTypesNotif.filter((el: any) => el.id === type)[0]?.icon
    } else return ''
  }

  getColorStatus(status:  keyof typeof StatusNotificationEnum){
    return StatusNotificationEnumColor[status]? StatusNotificationEnumColor[status] : "medium"
  }

  showActions(){
    this.clickItem.emit();
  }



  async showImages(status: boolean = false) {

    this.isModalOpen = status;
  }

  editNotif(){
    if(this.clickEditItem) this.clickEditItem.emit()
  }

  deleteNotif(){
    if(this.clickDeleteItem) this.clickDeleteItem.emit()
  }

  getStatusLabel(status: keyof typeof StatusNotificationEnum){
    return StatusNotificationEnum[status]? StatusNotificationEnum[status] : status
  }

  ////////////////////////////////////////////////////////////////////

  enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(350)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  leaveAnimation = (baseEl: HTMLElement) => {
    return this.enterAnimation(baseEl).direction('reverse');
  };
}
