import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { SwiperComponent } from './swiper/swiper.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapModal } from './google-map-modal/google-map-modal.page';
import { SucessfullScreenComponent } from './sucessfull-screen/sucessfull-screen.component';
import { ItemNotificationComponent } from './item-notification/item-notification.component';
import { ModalConfirmDeleteComponent } from '../../app/dashboard/children/billboard/components/modal-confirm-delete/modal-confirm-delete.component';
import { ModalSelectDateComponent } from '@app/dashboard/children/accounting/childrens/reports-list/components/modalSelectDate/modalSelectDate.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { NotifyAlertComponent } from './notifiyAlert/notifiy-alert.component';
import { UploadBulkDataComponent } from './upload-bulk-data/upload-bulk-data.component';
import { ModalUploadBulkComponent } from './modal-upload-bulk/modal-upload-bulk.component';
import { ModalNotificationPushComponent } from './modal-notification-push/modal-notification-push.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FilterComponent } from './filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { PhonePipe } from 'src/pipe/phone.pipe';
import { LoadingRouteComponent } from './loading-route/loading-route.component';


@NgModule({
  declarations: [
    HeaderComponent,
    LoadingScreenComponent,
    SwiperComponent,
    GoogleMapModal,
    NotifyAlertComponent,
    SucessfullScreenComponent,
    LoadingRouteComponent,
    ItemNotificationComponent,
    ModalConfirmDeleteComponent,
    ModalConfirmComponent,
    ModalSelectDateComponent,
    UploadBulkDataComponent,
    ModalUploadBulkComponent,
    ModalNotificationPushComponent,
    PaginationComponent,
    FilterComponent,
    PhonePipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
        modules: {
          toolbar: [
            ['bold', 'italic' ],        // toggled buttons
            // ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
            // [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction
            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{ 'align': [] }],
            // ['image']                         // link and image, video
          ]
        },
        theme: 'snow',
        debug: 'warn',
        placeholder: '',
    })
  ],
  exports: [
    QuillModule,
    HeaderComponent,
    LoadingScreenComponent,
    SwiperComponent,
    GoogleMapModal,
    NotifyAlertComponent,
    SucessfullScreenComponent,
    ItemNotificationComponent,
    ModalConfirmDeleteComponent,
    LoadingRouteComponent,
    ModalSelectDateComponent,
    ModalConfirmComponent,
    UploadBulkDataComponent,
    ModalUploadBulkComponent,
    ModalNotificationPushComponent,
    PaginationComponent,
    FilterComponent,
    PhonePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ PhonePipe ]
})
export class CommonComponentsModule { }
