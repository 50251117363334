import { NgModule } from '@angular/core';
import { NavigationApp } from 'src/navegation';
import { CheckLoginGuard } from 'src/guards/check-login.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CheckAuthenticatedGuard } from 'src/guards/check-authenticated.guard';
import { ValidateAuthValidateAccessGuard } from 'src/guards/check-auth-validate-access.guard';
import { StatusAppGuard } from 'src/guards/check-status-app.guard';
import { LoadPermissionsResolver } from 'src/guards/load-permissions.resolver';

const routes: Routes = [
  {
    path: NavigationApp.STATUS_APP,
    loadChildren: () => import('./status-app/status-app.module').then( m => m.StatusAppPageModule)
  },
  {
    path: NavigationApp.LOGIN,
    canActivate: [StatusAppGuard, CheckLoginGuard],
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: NavigationApp.VALIDATE_ACCESS,
    canActivate: [ValidateAuthValidateAccessGuard],
    loadChildren: () => import('./validate-access/validate-access.module').then( m => m.ValidateAccessPageModule)
  },
  {
    path: NavigationApp.RESET_PASSWORD,
    canActivate: [CheckLoginGuard, StatusAppGuard],
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: NavigationApp.DASHBOARD,
    resolve: { data: LoadPermissionsResolver },
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'register/:uniqueCode/:id',
    canActivate: [StatusAppGuard],
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'register/:uniqueCode/:id/:roleTenant',
    canActivate: [StatusAppGuard],
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  { path: '', pathMatch: "full", redirectTo: NavigationApp.LOGIN },
  {
    path: 'first-access',
    canActivate: [ValidateAuthValidateAccessGuard],
    loadChildren: () => import('./first-access/first-access.module').then( m => m.FirstAccessPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
