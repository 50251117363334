export enum ModuleBackend {
  USER = 'users',
  VERSION = 'version',
  AUTH = 'auth',
  AUTH_CLIENT = 'auth_client',
  MODULE = "module",
  ROLES = "roles",
  CLIENTS = "clients",
  EMPLOYEES = 'employees',
  PROPERTY = "property",
  COMPOUND = "compound",
  REQUEST_CLIENTS = "request-client",
  PLANS = "plans",
  PLAN_CLIENTS = "plan_clients",
  CHANGE_PLAN_CLIENT = "plan_clients/change_plan_client",
  DELETE_PLAN_CLIENT = "plan_clients/remove_with_client",
  PAYMENT_METHOD = "payments-accounts",
  PAYMENTS = "payments",
  PAYMENTS_CLIENT = "payments_client",
  PAYMENTS_RECEIPT = "payments-receipt",
  RECEIPT = "receipt",
  SERVICES = "services",
  CONSULTANCY = "consultancy",
  CONTACT = "contact",
  TABLAS_TIPO = "table-types/all",
  PROPERTY_OWNER = 'propery-owner',
  PROPERTY_OWNER_CLIENT = 'property-owner-client',
  COMPOUND_CLIENT = "compound_client",
  PROPERTY_CLIENT = 'property_client',
  NOTIFICATION = 'notification',
  CONDITION = 'common-area-condition',
  SURVEY_ADMIN = 'survey',
  SURVEY = 'survey-response',
  EVENTS = 'event',
  EVENT_REQUEST = 'event-request',
  COMMON_AREA = 'common-area',
  COMMON_AREA_PHOTOS = 'common-area-photo',
  BIILS = 'bills',
  INVOICES = "invoices",
  BIILS_CLIENT = 'bills_client',
  CURRENCIES = 'currencies',
  PAYMENTS_TYPE_CLIENT = 'payments-type-client',
  ADMIN_RECEIPTS = 'receipt',
  ADMIN_RECEIPTS_CHARGE = 'charge-receipts',
  ADMIN_RECEIPTS_PAYMENTS = 'payments-receipt',
  MODULE_GROUPS_CLIENT = 'module_groups_client',
  ASSISTANTS_CLIENT = 'auxiliary_client',
  ACCOUNTING_ACCOUNTS = 'accounting-accounts-client',
  VOUCHER_CLIENT = 'voucherClient',
  JOURNAL_CLIENT = 'AccountingJournalClient',
  TYPE_ASSISTANTS = 'type_auxiliary_client',
  MODULE_GROUP_CONFIG_CLIENT= 'module_group_config_client',
  ALIQUOTS_SCLIENT = 'aliquots_client',
  ACCOUNTING_RECEIPT = 'accounting-receipt',
  ACCOUNTING_DOCUMENTS_TYPE_CLIENT= 'accounting-document-type-client',
  RECEIPT_CLIENT = 'receipt-client',
  INVENTORY = 'warehouses',
  MANAGEMENT = 'management',
  CLIENT_KYC = 'client_kyc',
  USER_CONTROL = 'user-controls',
}
