import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { IErrorResponse } from '@interfaces/*';
import { MessagesAlerts } from '@common/index';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private messagesAlerts: MessagesAlerts,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  sendPayment(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
     )
  }

  getData(Module: any){
    return this.http.get(
      `${this.API_URI}/${Module}`,
      this.headerComplement.complementHeader()
    );
  }

  getAccountDebts(Module: ModuleBackend){
    return this.http.get(
      `${this.API_URI}/${Module}/accounts/debts`,
      this.headerComplement.complementHeader()
    );
  }

  check_payment(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}/check_payment`,
      data,
      this.headerComplement.complementHeader()
    )
  }

  getDataParams(Module: string, params: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${params.pagination.currentPage}&skip=${params.pagination.skip}${ (params.paymentStatus != null) ? '&paymentStatus='+params.paymentStatus :''}${ (params.propertyUserId != null) ? '&propertyUserId='+params.propertyUserId :''}${ (params.myOwnPayments != null) ? '&myOwnPayments='+params.myOwnPayments :''}${ (params.receiptId != null) ? '&receiptId='+params.receiptId :''}${ (params.propertyId != null && params.propertyId != 0) ? '&propertyId='+params.propertyId :''}${ (params.status != null && params.status != 0) ? '&status='+params.status :''}${ (params.dateIssue != null && params.dateIssue != null) ? '&dateIssue='+params.dateIssue :''}${ (params.clientId != null && params.clientId != null) ? '&clientId='+params.clientId :''}${ (params.type != null && params.type == 2) ? '&type='+params.type: ''}${ (params.voucherId != null) ? '&voucherId='+params.voucherId: ''}`,
      this.headerComplement.complementHeader()
    );
  }

  getDataParamsDisplayUserDefaulters(Module: ModuleBackend, params: any){
    return this.http.get(
      `${this.API_URI}/${Module}/receipt/displayUserDefaulters/v2?page=${params.pagination.currentPage}&skip=${params.pagination.skip}`,
      this.headerComplement.complementHeader()
    );
  }

  getDisplayUserDefaulters(Module: ModuleBackend, params: any){
    return this.http.get(
      `${this.API_URI}/${Module}/receipt/displayUserDefaulters/accounting?page=${params.pagination.currentPage}&skip=${params.pagination.skip}${ (params.compoundId != null) ? '&compoundId='+params.compoundId :''}`,
      this.headerComplement.complementHeader()
    );
  }

  getDataParamsStatus(Module: ModuleBackend, params: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${params.pagination.currentPage}&skip=${params.pagination.skip}${ (params.paymentStatus != null) ? '&paymentStatus='+params.paymentStatus :''}${ (params.propertyUserId != null) ? '&propertyUserId='+params.propertyUserId :''}${ (params.myOwnPayments != null) ? '&myOwnPayments='+params.myOwnPayments :''}${ (params.receiptId != null) ? '&receiptId='+params.receiptId :''}${ (params.propertyId != null && params.propertyId != 0) ? '&propertyId='+params.propertyId :''}${ (params.status != null && params.status != null) ? '&status='+params.status :''}${ (params.clientId != null && params.clientId != null) ? '&clientId='+params.clientId :''}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllPaymentsMethod(Module: ModuleBackend,isEffective: number = 0, pagination: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.page}&skip=${pagination.skip}&isEffective=${isEffective}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllPaymentsMethodFindAll(Module: ModuleBackend, isEffective: number = 0, pagination: any){
    return this.http.get(
      `${this.API_URI}/${Module}/propertyUser/findAll?page=${pagination.currentPage}&skip=${pagination.skip}&isEffective=${isEffective}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllPaymentsMethodFindOne(Module: ModuleBackend, id: number){
    return this.http.get(
      `${this.API_URI}/${Module}/propertyUser/findOne/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getPaymentMethod(Module: ModuleBackend, id: any){
    return this.http.get(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  createInvoces(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
     )
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }
}
