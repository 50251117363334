import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { LocalStorageService } from './common';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getAllEvents(Module: ModuleBackend, pagination: any, filters?: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.page}&skip=${pagination.skip}${filters && filters.byDate? '&byDate='+filters.byDate : ''}${filters && filters.startDate? '&startDate='+filters.startDate : ''}${filters && filters.dueDate? '&dueDate='+filters.dueDate : ''}${filters && filters.commonAreaId? '&commonAreaId='+filters.commonAreaId : ''}`,
      this.headerComplement.complementHeader()
    );
  }

  createEvent(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updateEvent(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  deleteEvent(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  rejectEv(Module: ModuleBackend, id: number, data: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/rejectEvent/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

}
