import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';


@Injectable({
  providedIn: 'root'
})
export class RequestEventsService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getAll(Module: ModuleBackend, pagination: any, filter?: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.page}&skip=${pagination.skip}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllMyRequest(Module: ModuleBackend, pagination: any, filters?: any){
    return this.http.get(
      `${this.API_URI}/${Module}/auth/event_request?page=${pagination.page}&skip=${pagination.skip}${filters && filters.byDate? '&byDate='+filters.byDate : ''}${filters && filters.startDate? '&startDate='+filters.startDate : ''}${filters && filters.dueDate? '&dueDate='+filters.dueDate : ''}`,
      this.headerComplement.complementHeader()
    );
  }

  create(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  delete(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  update(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  changestatusRequestEv(Module: ModuleBackend, data: any, id: any){ // ep usado para Aprobar y Rechazar Solicitudes de eventos.
    return this.http.post(
      `${this.API_URI}/${Module}/approve_request/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  rejectRequest(Module: ModuleBackend, id: number, data: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/rejectRequestEvent/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }
}
