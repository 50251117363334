import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { MessagesAlerts } from '@common/index';
import { IErrorResponse } from '@interfaces/index';


@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private messagesAlerts: MessagesAlerts,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getListProperties(Module: ModuleBackend){
    return this.http.get(
      `${this.API_URI}/${Module}/all_eager_loading?page=1&skip=1000000000`,
      this.headerComplement.complementHeader()
    );
  }

  createProduct(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  createManagement(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updateProduct(Module: ModuleBackend,id: number, data: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  getDataParams(Module: string, params: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${params.pagination.currentPage}&skip=${params.pagination.skip}${ (params.paymentStatus != null) ? '&paymentStatus='+params.paymentStatus :''}${ (params.propertyUserId != null) ? '&propertyUserId='+params.propertyUserId :''}${ (params.myOwnPayments != null) ? '&myOwnPayments='+params.myOwnPayments :''}${ (params.receiptId != null) ? '&receiptId='+params.receiptId :''}${ (params.propertyId != null && params.propertyId != 0) ? '&propertyId='+params.propertyId :''}${ (params.status != null && params.status != 0) ? '&status='+params.status :''}${ (params.dateIssue != null && params.dateIssue != null) ? '&dateIssue='+params.dateIssue :''}${ (params.clientId != null && params.clientId != null) ? '&clientId='+params.clientId :''}${ (params.type != null && params.type == 2) ? '&type='+params.type: ''}${ (params.voucherId != null) ? '&voucherId='+params.voucherId: ''}${ (params.typeInventory != null) ? '&type='+params.typeInventory: ''}`,
      this.headerComplement.complementHeader()
    );
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }

}
