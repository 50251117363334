import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss'],
})
export class ModalConfirmDeleteComponent  implements OnInit {

  @Input() questionConfirm:  string = '';
  @Input() detailElement: string = '';

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  cancel(){
    this.modalCtrl.dismiss(false)
  }

  delete(){
    this.modalCtrl.dismiss(true)
  }

}
